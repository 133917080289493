import React from "react"
import Module from "../components/Module"
import Box from "@material-ui/core/Box"
import { Container } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import VisibilitySensor from "../animations/VisibilitySensor"
import KlarnaPlacement from "../components/KlarnaPlacement"
import clsx from "clsx"
import "../style/KlarnaWebMessagingModule.css"

const useStyles = makeStyles(theme => ({
  Klarna: {},
}))

const KlarnaWebMessaging = props => {
  const { repo } = props
  const classes = useStyles()

  return (
    <VisibilitySensor>
      <Module className={clsx(classes.Klarna, "KlarnaWebMessaging")}>
        <Container maxWidth="md">
          <Box textAlign="center">
            <KlarnaPlacement repo={repo} moduleName={"KlarnaWebMessaging"} />
          </Box>
        </Container>
      </Module>
    </VisibilitySensor>
  )
}

export default KlarnaWebMessaging
