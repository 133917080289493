import React, { useEffect, useState } from "react"
import Module from "../components/Module"
import { Box, colors, Container, Grid, MenuItem } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import VisibilitySensor from "../animations/VisibilitySensor"
import CtaButton from "../components/CtaButton"
import Text from "../components/Text"
import Select from "../components/Select"
import clsx from "clsx"
import "keen-slider/keen-slider.min.css"
import "../style/EcommerceMonthlyItemModule.css"
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Navigation, Pagination, FreeMode, Thumbs } from "swiper"
import "swiper/swiper-bundle.css"
import { useTranslation } from "react-i18next"
import { GTMEvent, GTMEventAddToCart } from "../utils/gtm"

const useStyles = makeStyles(theme => ({
  subtitle: {
    marginTop: theme.spacing(2),
    color: "#662482",
  },
  subtitleNext: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  colorAvailable: {
    color: "#662482",
  },
  imageSlider: {
    height: "300px",
  },
  infoBlock: {
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(4),
    gap: "50px",
  },
  infoImage: {
    width: "100px",
    height: "100px",
    backgroundColor: "#ccc",
  },
  infoText: {
    ...theme.fonts.faces.bodyLhM,
    color: "white",
    "& span": {
      lineHeight: "1.25 !important",
    },
  },
  bulletIcon: {
    width: 24,
    height: 24,
  },
  price: {
    marginBottom: "24px",
    marginTop: "24px",
    fontSize: "1.5rem !important",
  },
  boxOffer: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    backgroundColor: "#F0F0F0",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    borderRadius: "2px",
    marginLeft: "16px",
    marginRight: "16px",
    gap: "8px",
    lineHeight: "normal",
    [theme.breakpoints.up("sm")]: {
      width: "100%",
      marginLeft: 0,
      marginRight: 0,
    },
  },
  buttonDisabled: {
    border: "1px solid #a1a1a1",
    backgroundColor: "#d6d6d6",
    color: "#a1a1a1",
    pointerEvents: "none",
    backgroundImage: "none",
    cursor: "not-allowed",
  },
  iconNext: {
    width: "204px",
    padding: theme.spacing(1),
  },
  containerButton: {
    display: "flex",
    justifyContent: "space-between",
    gap: "8px",
  },
  w50: {
    width: "50%",
  },
  w100: {
    width: "100%",
  },
  backgroundContentImg: {
    backgroundColor: "#d9d9d9",
    marginLeft: "auto",
  },
  textRight: {
    textAlign: "right",
  },
  priceStrikethrough: {
    fontSize: "13px !important",
    fontWeight: "400",
    textDecoration: "line-through",
    lineHeight: "15.2px !important",
    margin: "0px !important",
  },
  offerPrice: {
    fontSize: "17px !important",
    fontWeight: "800",
    lineHeight: "15.2px !important",
    color: "#000000",
    margin: "0px !important",
  },
  commercialMessage: {
    fontSize: "18px !important",
    fontWeight: "400",
    lineHeight: "15.2px !important",
    [theme.breakpoints.down("md")]: {
      marginBottom: "15px !important",
      lineHeight: "24px !important",
    },
  },
  containerOfferPrice: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
    },
  },
}))

SwiperCore.use([Navigation, Pagination])

SwiperCore.use([Thumbs])

const EcommerceMonthlyItemModule = props => {
  const { title, product, distributionType, indexPicture, repo, cta, cart } =
    props
  const classes = useStyles()
  const [currentSelect, setCurrentSelect] = React.useState(indexPicture || 0)
  const nextPack = indexPicture + 1 || currentSelect + 1
  const [figuresItem, setFiguresItem] = React.useState([])
  const [dateFormatted, setDateFormatted] = React.useState("")
  const [isActive, setIsActive] = React.useState(false)

  const [thumbsSwiper, setThumbsSwiper] = useState(null)
  const { t } = useTranslation()
  const sku =
    product?.field_value[currentSelect]?.figure_ecommerce_code ||
    product?.field_value[indexPicture]?.field_value[
      `figure_${distributionType}_code`
    ]

  useEffect(() => {
    let array = []
    if (product?.field_value[0]?.figure_image) {
      array = [product?.field_value[currentSelect]?.figure_image?.field_value]
      product?.field_value[currentSelect]?.figure_gallery?.field_value?.forEach(
        figure =>
          array.push(figure?.field_value?.figure_gallery_image?.field_value)
      )
    } else {
      array = [
        product?.field_value[currentSelect]?.field_value?.figure_image
          ?.field_value,
      ]
      product?.field_value[
        currentSelect
      ]?.field_value?.figure_gallery?.field_value?.forEach(figure =>
        array.push(figure?.field_value?.figure_gallery_image?.field_value)
      )
    }

    setFiguresItem(array)

    const dateNextPack =
      product?.field_value[nextPack]?.figure_ecommerce_date?.field_value ||
      product?.field_value[nextPack]?.field_value[
        `figure_${distributionType}_date`
      ]?.field_value
    const date = new Date(dateNextPack)
    const options = { day: "numeric", month: "short" }
    const formattedDate = date.toLocaleDateString(
      repo?.seo?.siteLanguage,
      options
    )
    setDateFormatted(formattedDate)
    if (cart) {
      console.log("call reloadListeners")
      cart.reloadListeners()
    }

    const isEcommerceItemActive = activationDate => {
      const inputDate = new Date(activationDate)
      const today = new Date()
      today.setHours(0, 0, 0, 0)
      return inputDate.getTime() < today.getTime()
    }

    const activationDate =
      product?.field_value[currentSelect]?.figure_ecommerce_date?.field_value ||
      product?.field_value[currentSelect]?.field_value[
        `figure_${distributionType}_date`
      ]?.field_value
    setIsActive(isEcommerceItemActive(activationDate))
  }, [])

  const handleProduct = index => {
    let array = []
    array = [product?.field_value[index]?.figure_image?.field_value]
    product?.field_value[index]?.figure_gallery?.field_value?.forEach(figure =>
      array.push(figure?.field_value?.figure_gallery_image?.field_value)
    )

    setFiguresItem(array)

    const dateNextPack =
      product?.field_value[index + 1]?.figure_ecommerce_date?.field_value
    const date = new Date(dateNextPack)
    const options = { day: "numeric", month: "short" }
    const formattedDate = date.toLocaleDateString(
      repo.seo.siteLanguage,
      options
    )
    setDateFormatted(formattedDate)
  }

  function formatPrice(price) {
    if (typeof price === "string") {
      return price.replace(",", ".")
    }
    return price
  }

  return (
    <VisibilitySensor>
      <Module
        className={clsx(
          //classes.EcommerceMonthlyItemModule,
          "EcommerceMonthlyItem"
        )}
      >
        <Container>
          <Text
            variant="titleXL"
            component="h2"
            overline
            weight="bold"
            align="center"
          >
            {title?.field_value}
          </Text>
          {/* START BOX PREVIEW */}
          {process.env.NODE_ENV === "development" &&
            product?.field_value[currentSelect]?.figure_ecommerce_date
              ?.field_value && (
              <Box px={2} my={5} mx={"auto"} className={classes.select}>
                <Select
                  value={currentSelect}
                  onChange={select => {
                    setCurrentSelect(select?.target?.value),
                      handleProduct(select?.target?.value)
                  }}
                >
                  {product.field_value.map((item, index) => {
                    return (
                      <MenuItem key={index} value={index}>
                        {item?.figure_ecommerce_title?.field_value +
                          " - " +
                          item?.figure_ecommerce_date?.field_value}
                      </MenuItem>
                    )
                  })}
                </Select>
              </Box>
            )}

          <Grid spacing={4} className={clsx("container__main", "mt-10")}>
            <Grid className={clsx("section__slider")}>
              <Swiper
                style={{
                  "--swiper-navigation-color": "#f29100",
                  "--swiper-pagination-color": "#f29100",
                }}
                spaceBetween={15}
                navigation={true}
                loop={true}
                thumbs={{ swiper: thumbsSwiper }}
                loopedSlides={figuresItem.length}
                modules={[FreeMode, Navigation, Thumbs]}
                className={`${
                  figuresItem.length > 1 ? "d-block" : "d-none"
                } swiper__core`}
                onSwiper={swiper => {
                  console.log("Thumbnail Swiper:", swiper)
                  setThumbsSwiper(swiper)
                }}
              >
                {figuresItem.map(imgFirstSlider => {
                  console.log(imgFirstSlider, "__IMAGE")
                  return (
                    <SwiperSlide>
                      <div
                        className="background-cover"
                        style={{
                          background: `url(${imgFirstSlider?.src ?? ""})`,
                          color: `green`,
                        }}
                      ></div>
                    </SwiperSlide>
                  )
                })}
              </Swiper>
              <Swiper
                loop={true}
                onSwiper={setThumbsSwiper}
                spaceBetween={15}
                slidesPerView={4}
                freeMode={true}
                watchSlidesProgress={true}
                modules={[FreeMode, Navigation, Thumbs]}
                className={`${
                  figuresItem.length > 1 ? "d-block" : "d-none"
                } swiper__thumb`}
              >
                {figuresItem.map(imgFirstSlider => {
                  return (
                    <SwiperSlide>
                      <div
                        className="background-cover"
                        style={{
                          background: `url(${imgFirstSlider?.src ?? ""})`,
                          color: `#ff0000`,
                        }}
                        onClick={() =>
                          GTMEvent(repo, "click_image", "slider_image-module")
                        }
                      ></div>
                    </SwiperSlide>
                  )
                })}
              </Swiper>
              <Swiper
                spaceBetween={15}
                thumbs={{ swiper: thumbsSwiper }}
                className={`${
                  figuresItem.length <= 1 ? "d-block" : "d-none"
                } swiper__core`}
              >
                <SwiperSlide>
                  <div
                    className="background-cover"
                    style={{
                      background: `url(${figuresItem[0]?.src ?? ""})`,
                      color: `green`,
                    }}
                  ></div>
                </SwiperSlide>
              </Swiper>
            </Grid>
            <Grid className={clsx("section__title")}>
              <Text
                variant="titleXXL"
                //className={classes.subtitle}
                component="h2"
                weight="bold"
              >
                {product?.field_value[currentSelect]
                  ?.figure_ecommerce_title_html?.field_value ||
                  product?.field_value[indexPicture]?.field_value
                    ?.figure_title_html?.field_value}
              </Text>
            </Grid>
            <Grid className={clsx("section__text")}>
              <p className="mb-5">
                <Text
                  variant="titleL"
                  weight="bold"
                  tag="span"
                  className={classes.price}
                >
                  {formatPrice(
                    product?.field_value[currentSelect]?.figure_ecommerce_price
                      ?.field_value ||
                      product?.field_value[indexPicture]?.field_value[
                        `figure_${distributionType}_price`
                      ]?.field_value
                  )}
                </Text>

                <Text
                  className="boxDescription"
                  dangerouslySetInnerHTML={{
                    __html:
                      product?.field_value[currentSelect]
                        ?.figure_ecommerce_description?.field_value ||
                      product?.field_value[indexPicture]?.field_value
                        ?.figure_description?.field_value,
                  }}
                ></Text>
              </p>
              {(product?.field_value[currentSelect]?.commercial_message_text
                ?.field_value ||
                product?.field_value[indexPicture]?.field_value[
                  `figure_${distributionType}_commercial_message_text`
                ]?.field_value) && (
                <Box className={classes.boxOffer}>
                  <Text
                    variant="titleL"
                    className={classes.commercialMessage}
                    dangerouslySetInnerHTML={{
                      __html:
                        product?.field_value[currentSelect]
                          ?.commercial_message_text?.field_value ||
                        product?.field_value[indexPicture]?.field_value[
                          `figure_${distributionType}_commercial_message_text`
                        ]?.field_value,
                    }}
                  />
                  <div className={classes.containerOfferPrice}>
                    <Text
                      variant="titleL"
                      className={classes.offerPrice}
                      dangerouslySetInnerHTML={{
                        __html:
                          product?.field_value[currentSelect]
                            ?.commercial_promo_price?.field_value ||
                          product?.field_value[indexPicture]?.field_value[
                            `figure_${distributionType}_commercial_promo_price`
                          ]?.field_value,
                      }}
                    />

                    <Text
                      variant="titleL"
                      className={classes.priceStrikethrough}
                    >
                      {formatPrice(
                        product?.field_value[currentSelect]
                          ?.figure_ecommerce_price?.field_value ||
                          product?.field_value[indexPicture]?.field_value[
                            `figure_${distributionType}_price`
                          ]?.field_value
                      )}
                    </Text>
                  </div>
                </Box>
              )}
              <Box className={clsx("buttons-container")}>
                <div class="buttonCartContainer container-button-add-to-cart button-action">
                  {isActive ? (
                    <button
                      data-sku={sku?.field_value}
                      className={clsx(
                        "add-cart-item-trigger button-add-to-cart"
                      )}
                      disabled
                    >
                      <Text
                        tag="span"
                        className="container-text-add-to-cart"
                        onClick={() => {
                          GTMEvent(
                            repo,
                            "starship_ofthemonth_module",
                            "buy_now"
                          )
                          GTMEventAddToCart(
                            repo ?? "",
                            sku ?? "",
                            product?.field_value[currentSelect]
                              ?.figure_ecommerce_price ||
                              product?.field_value[indexPicture]?.field_value[
                                `figure_${distributionType}_price`
                              ] ||
                              "",
                            product?.field_value[currentSelect]
                              ?.currency_code ||
                              product?.field_value[indexPicture]?.field_value[
                                `figure_${distributionType}_currency_code`
                              ] ||
                              ""
                          )
                        }}
                      >
                        {product?.field_value[currentSelect]
                          ?.figure_cta_addtocart?.field_value ||
                          product?.field_value[indexPicture]?.field_value
                            ?.figure_cta_addtocart?.field_value}
                      </Text>
                    </button>
                  ) : (
                    <button
                      data-sku={sku?.field_value}
                      className={clsx("button-add-to-cart")}
                      disabled
                    >
                      {product?.field_value[currentSelect]?.figure_cta_addtocart
                        ?.field_value ||
                        product?.field_value[indexPicture]?.field_value
                          ?.figure_cta_addtocart?.field_value}
                    </button>
                  )}
                </div>
                {cta?.field_value?.product_code && (
                  <CtaButton
                    className={clsx(
                      "button-subscribe",
                      "button-action",
                      classes.w50
                    )}
                    {...cta.field_value}
                    size="large"
                    trackingObj={repo}
                    trackingAction="starship_ofthemonth_module"
                    trackingLabel={"Subscribe"}
                  />
                )}
              </Box>
              {(product?.field_value[nextPack]?.figure_ecommerce_title
                ?.field_value ||
                product?.field_value[indexPicture + 1]?.field_value
                  ?.figure_title?.field_value ||
                product?.field_value[nextPack]?.figure_image?.field_value
                  ?.src ||
                product?.field_value[indexPicture + 1]?.field_value
                  ?.figure_image?.field_value?.src) && (
                <Box className="next-ship">
                  <Box className="text">
                    <Text>{t("ecommerce.comingNextMonth")}</Text>
                    <Text
                      variant="titleL"
                      weight="bold"
                      tag="span"
                      className={classes.subtitleNext}
                    >
                      {product?.field_value[nextPack]
                        ?.figure_ecommerce_title_html?.field_value ||
                        product?.field_value[indexPicture + 1]?.field_value
                          ?.figure_title_html?.field_value}
                    </Text>
                    <Text className={classes.colorAvailable}>
                      {t("ecommerce.labelAvailableDate") + dateFormatted}
                    </Text>
                  </Box>
                  <div className="image">
                    <img
                      className={classes.iconNext}
                      src={
                        product?.field_value[nextPack]?.figure_image
                          ?.field_value?.src ||
                        product?.field_value[indexPicture + 1]?.field_value
                          ?.figure_image?.field_value?.src
                      }
                    ></img>
                  </div>
                </Box>
              )}
            </Grid>
          </Grid>
        </Container>
      </Module>
    </VisibilitySensor>
  )
}

export default EcommerceMonthlyItemModule
