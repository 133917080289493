import React, { useState, useEffect, useRef } from "react"
import clsx from "clsx"
import { makeStyles } from "@material-ui/core/styles"
import { Box, MenuItem, useMediaQuery, useTheme } from "@material-ui/core"
import { useInView } from "react-intersection-observer"
import Module from "../components/Module"
import Text from "../components/Text"
import FigureCard from "../components/FigureCard"
import CtaButton from "../components/CtaButton"
import Button from "../components/Button"
import Overlay from "../components/Overlay"
import FigureSelector from "../components/FigureSelector"
import Gallery from "../components/Gallery"
import InfoCards from "../components/InfoCards"
import VideoDescription from "../components/VideoDescription"
import Slider from "../components/Slider"
import { window } from "browser-monads"
import Select from "../components/Select"
import { GTMEvent } from "../utils/gtm"

const useStyles = makeStyles(theme => ({
  figuresGrid: {
    textAlign: "center",
    marginTop: theme.spacing(4),
    padding: theme.spacing(1 / 4),
  },
  container: {
    display: "grid",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "repeat(auto-fill, minmax(50%, 1fr))",
    },
  },
  highlighted: {
    [theme.breakpoints.down("sm")]: {
      gridColumn: "span 2",
    },
  },
  subtitle: {
    paddingBottom: theme.spacing(4),
  },
  description: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
  ctaButton: {
    marginBottom: theme.spacing(5),
    textTransform: "none",
  },
  biggerGap: {
    marginTop: "80px!important",
    margin: "0 8px!important",
    [theme.breakpoints.down("md")]: {
      margin: "0 4px!important",
      marginTop: "40px!important",
    },
  },
  bigGap: {
    marginTop: "50px!important",
    [theme.breakpoints.down("md")]: {
      marginTop: "30px!important",
    },
  },
  bottomGap: {
    marginBottom: "30px!important",
  },
  figureContainer: {
    padding: theme.spacing(1 / 2),
  },
  curiosities: {
    marginBottom: theme.spacing(7),
  },
  primaryGallery: {
    margin: "0 8px",
    [theme.breakpoints.down("md")]: {
      margin: "0 4px",
    },
  },
  animatedBox: {
    opacity: 0,
  },
  notAnimatedBox: {
    opacity: 1,
  },
  fullHeightBox: {
    // height: "100%",
    padding: "0 2.5px",
  },
  firstSlide: {
    width: "100%",
    height: "375px",
    [theme.breakpoints.up("sm")]: {
      width: 426,
      height: 518,
    },
  },
  otherSlides: {
    width: "100%",
    height: "375px",
    [theme.breakpoints.up("sm")]: {
      width: 341,
      height: 426,
    },
  },
  secondaryGallery: {
    margin: "0 8px 8px 8px",
    gridTemplateAreas: '"a0 a0 a1 a2"\n"a0 a0 a3 aOthers"',
    [theme.breakpoints.down("md")]: {
      margin: "0 4px 8px 4px",
      gridTemplateAreas: '"a0 a0"\n"a0 a0"\n"a1 a2"\n"a3 aOthers"',
    },
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "repeat( auto-fit, minmax(150px, 1fr) )",
      gridTemplateRows: "repeat( auto-fit, minmax(50px, 372px) )",
    },
  },
  slider: {
    "& .slick-list": {
      overflow: "visible",
    },
    "& .slick-track": {
      alignItems: "center",
    },
  },
  select: {
    width: "100%",
    maxWidth: "427px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  figure: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    paddingBottom: "20px",
    paddingLeft: 16,
    paddingRight: 16,
  },
  flexImage: {
    flex: "1 1 0",
    objectFit: "contain",
    width: "100%",
  },
}))

const FiguresGridModule = props => {
  const classes = useStyles()
  const {
    title,
    subtitle,
    description,
    items,
    cta,
    overlayTitle,
    is_slider,
    ...others
  } = props

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))
  const { ref, inView, entry } = useInView()
  const [animate, setAnimate] = React.useState(true)
  const [openOverlay, setOpenOverlay] = useState(false)
  const [itemsWithPosition, setItemsWithPositions] = useState([])
  const [currentFigure, setCurrentFigure] = useState()
  const [indexSlider, setIndexSlider] = useState(0)

  // const sliderSettings = {
  //   tracking: others.repo,
  //   dots: false,
  //   arrows: true,
  //   speed: 500,
  //   slidesToShow: 5.5,
  //   slidesToScroll: 1,
  //   className: classes.slider,
  //   centerMode: true,
  //   responsive: [
  //     {
  //       breakpoint: 1060,
  //       settings: {
  //         slidesToShow: 4.5,
  //         slidesToScroll: 1,
  //       },
  //     },
  //     {
  //       breakpoint: 860,
  //       settings: {
  //         slidesToShow: 3.5,
  //         slidesToScroll: 1,
  //       },
  //     },
  //     {
  //       breakpoint: 600,
  //       settings: {
  //         slidesToShow: 2.5,
  //         slidesToScroll: 1,
  //       },
  //     },
  //   ],
  // }

  const sliderSettingsNew = {
    tracking: others.repo,
    dots: false,
    infinite: true,
    arrows: true,
    speed: 500,
    slidesToShow: 5,
    variableWidth: true,
    slidesToScroll: 1,
    centerMode: true,
    responsive: [
      {
        breakpoint: 1060,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          centerMode: true,
          infinite: true,
          variableWidth: true,
        },
      },
      {
        breakpoint: 860,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          centerMode: true,
          infinite: true,
          variableWidth: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1.3,
          slidesToScroll: 1,
          centerMode: false,
          infinite: false,
          variableWidth: false,
        },
      },
    ],
  }

  useEffect(() => {}, [])

  const handleFigureSelect = figure => {
    setCurrentFigure(figure)
  }

  const handleFigureClick = figure => {
    setOpenOverlay(true)
    setCurrentFigure(figure)

    if (figure.name && others.repo) {
      GTMEvent(others.repo, "click_figure", figure.name)
    }
  }
  const handleOverlayClose = () => {
    setOpenOverlay(false)
  }

  useEffect(() => {
    setItemsWithPositions(
      (items || []).map((item, index) => ({
        ...item,
        position: index,
      }))
    )
  }, [items])

  useEffect(() => {
    if (animate && inView && ref) {
      setAnimate(false)
      Array.from(entry.target.children).forEach((sub, i) => {
        sub.animate(
          [
            { opacity: 0, transform: `translateY(25%)` },
            { opacity: 1, transform: "translateY(0%)" },
          ],
          {
            // timing options
            duration: 500,
            delay: parseInt(i / 5) * 50,
          }
        )
        setTimeout(() => {
          sub.style.opacity = 1
        }, parseInt(i / 5) * 50 + 500)
      })
    }
  }, [ref, inView, entry])

  const IsObserverSupported = () => {
    return (
      "IntersectionObserver" in window &&
      "IntersectionObserverEntry" in window &&
      "intersectionRatio" in window.IntersectionObserverEntry.prototype
    )
  }

  // TODO questa logica DEVE arrivare da CMS
  const TEMPORARY_FIX =
    cta &&
    cta.href &&
    cta.href.includes("/subscribe") &&
    ((cta.href.includes("checkout.fanhome.com/") &&
      !cta.href.includes("testVersion")) ||
      cta.href.includes("p1597070c1tst-store.occa.ocs.oraclecloud.com/"))

  return (
    <Module className={classes.figuresGrid} variant="full">
      <Text
        align="center"
        component="h2"
        variant="titleXXXL"
        weight="bold"
        overline
        gutterBottom
      >
        {title}
      </Text>
      {subtitle && (
        <Text
          className={classes.subtitle}
          align="center"
          variant="bodyM"
          weight="medium"
        >
          {subtitle}
        </Text>
      )}

      {is_slider && (
        <Box px={2} my={5} mx={"auto"} className={classes.select}>
          <Select
            value={indexSlider}
            onChange={event => {
              setIndexSlider(event.target.value)
            }}
          >
            {itemsWithPosition.map((item, index) => {
              return (
                <MenuItem key={index} value={index}>
                  <Text
                    component="p"
                    align="center"
                    dangerouslySetInnerHTML={{
                      __html: item.mainContent?.title,
                    }}
                  />
                </MenuItem>
              )
            })}
          </Select>
        </Box>
      )}

      {is_slider ? (
        <Slider
          afterChange={index => setIndexSlider(index)}
          indexSlider={indexSlider}
          {...sliderSettingsNew}
        >
          {itemsWithPosition.map((item, index) => (
            <FigureCard
              onClick={() => {
                setIndexSlider(index)
                handleFigureClick(item)
              }}
              className={clsx(
                classes.notAnimatedBox,
                classes.fullHeightBox,
                {
                  [classes.highlighted]: item.highlighted,
                },
                indexSlider === index ? classes.firstSlide : classes.otherSlides
              )}
              flexImage={classes.flexImage}
              classesFigure={classes.figure}
              hover
              highlighted={item.highlighted}
              key={index}
              index={index}
              title={item.name}
              text={item.text}
              picture={item.picture}
            />
          ))}
        </Slider>
      ) : IsObserverSupported() ? (
        <Box
          ref={ref}
          className={classes.container}
          gridTemplateColumns={`repeat(${items.length},20%)`}
        >
          {itemsWithPosition.map((item, index) => (
            <FigureCard
              onClick={() => {
                handleFigureClick(item)
              }}
              className={clsx(classes.animatedBox, {
                [classes.highlighted]: item.highlighted,
              })}
              hover
              highlighted={item.highlighted}
              key={index}
              title={item.name}
              picture={item.picture}
            />
          ))}
        </Box>
      ) : (
        <Box className={classes.container}>
          {itemsWithPosition.map((item, index) => (
            <FigureCard
              onClick={() => {
                handleFigureClick(item)
              }}
              className={clsx(classes.notAnimatedBox, {
                [classes.highlighted]: item.highlighted,
              })}
              hover
              highlighted={item.highlighted}
              key={index}
              title={item.name}
              picture={item.picture}
            />
          ))}
        </Box>
      )}

      <Text
        className={classes.description}
        component="div"
        variant="titleL"
        weight="bold"
      >
        {description}
      </Text>
      {cta && cta.productCode && (
        <CtaButton
          className={classes.ctaButton}
          next
          size="large"
          {...cta}
          trackingObj={others.repo}
          trackingAction="click_link"
          trackingLabel={"FiguresGridModule - " + cta.productCode}
        >
          {cta.text}
        </CtaButton>
      )}
      {/*HOTFIX 26-07*/}
      {cta && cta.label && cta.href && TEMPORARY_FIX && (
        <CtaButton
          className={classes.ctaButton}
          next
          size="large"
          forceOverrideLinkTEMPORARY={cta.href}
          {...cta}
          trackingObj={others.repo}
          trackingAction="click_link"
          trackingLabel={"FiguresGridModule - " + (cta?.label || cta?.title)}
        >
          {cta.label}
        </CtaButton>
      )}
      {/*HOTFIX 26-07*/}
      {cta && cta.label && cta.href && !TEMPORARY_FIX && (
        <Button
          className={classes.ctaButton}
          next
          size="large"
          {...cta}
          trackingObj={others.repo}
          trackingAction="click_link"
          trackingLabel={"FiguresGridModule - " + (cta?.label || cta?.title)}
        >
          {cta.label}
        </Button>
      )}
      <Overlay
        stickyHeader
        title={overlayTitle}
        fullScreen
        open={openOverlay}
        onClose={handleOverlayClose}
      >
        <Box pb={1 / 2}>
          <FigureSelector
            figures={itemsWithPosition}
            selected={currentFigure}
            onSelect={handleFigureSelect}
            tracking={others.repo || null}
          />
        </Box>
        {currentFigure && (
          <>
            <Gallery
              className={classes.primaryGallery}
              gap={8}
              cols={4}
              smCols={4}
              photos={currentFigure.primaryGallery}
            ></Gallery>
            {currentFigure.curiosities && (
              <Module
                className={classes.curiosities + " " + classes.biggerGap}
                variant="medium"
              >
                <VideoDescription
                  {...currentFigure.mainContent}
                  tracking={others.repo || null}
                />
              </Module>
            )}
            {currentFigure.curiosities && (
              <Module
                className={
                  classes.curiosities +
                  " " +
                  classes.bigGap +
                  " " +
                  classes.bottomGap
                }
                variant="medium"
              >
                <InfoCards
                  {...currentFigure.curiosities}
                  tracking={others.repo || null}
                />
              </Module>
            )}
            <Gallery
              className={classes.secondaryGallery}
              gap={8}
              cols={4}
              smCols={4}
              photos={currentFigure.secondaryGallery}
            ></Gallery>
          </>
        )}
      </Overlay>
    </Module>
  )
}

FiguresGridModule.defaultProps = {
  items: [],
}

export default FiguresGridModule
